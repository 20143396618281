import {
  Component,
  inject
} from '@angular/core';
import {
  Router,
  RouterOutlet,
} from '@angular/router';

import {
  App,
  URLOpenListenerEvent
} from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { NotificationService } from './notification/notification.service';
import { CenterService } from './shared/services/center.service';
import { UpdateService } from './shared/services/update.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    HeaderComponent,
    FooterComponent,
  ],
  templateUrl: './app.component.html',
})
/**
 * Root component of the application
 */
export class AppComponent {
  private router = inject(Router);
  private notification = inject(NotificationService);
  private centerService = inject(CenterService);
  private updateService = inject(UpdateService);

  constructor() {
    this.initializeApp();
    this.checkUpdate().catch(err => console.error(err));
  }

  async checkUpdate(): Promise<void> {
    if (!Capacitor.isNativePlatform()) {
      return;
    }
    await this.updateService.update()
  }

  initializeApp(): void {
    if (Capacitor.isNativePlatform()) {
      App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
        const authSlug = event.url.split(".app").pop();
        if (authSlug) {
          this.router.navigateByUrl(authSlug)
            .catch(err => this.notification.open({ type: 'error', title: 'error', message: (err as Error).message }))
        }
        for (const center of this.centerService.centers()) {
          if (!event.url.startsWith(center.url)) {
            continue;
          }
          this.centerService.center.set(center);
          const slug = event.url.substring(center.url.length);
          this.router.navigateByUrl(slug)
            .catch(err => this.notification.open({ type: 'error', title: 'error', message: (err as Error).message }))
        }
        Browser.close()
      });
    }
  }
}
