import {
  inject,
  Pipe,
  PipeTransform
} from '@angular/core';
import {
  DomSanitizer,
  SafeHtml
} from '@angular/platform-browser';

@Pipe({
  standalone: true,
  name: 'byPass'
})
export class ByPassPipe implements PipeTransform {
  sanitizer = inject(DomSanitizer);

  transform(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
