import { CdkTrapFocus } from '@angular/cdk/a11y';
import {
  CurrencyPipe,
  NgClass,
  NgOptimizedImage
} from '@angular/common';
import {
  AfterViewInit,
  Component,
  computed,
  inject,
  signal
} from '@angular/core';
import {
  takeUntilDestroyed,
  toObservable
} from '@angular/core/rxjs-interop';
import { RouterLink } from '@angular/router';
import { Keyboard } from '@capacitor/keyboard';
import {
  NgxTolgeeModule,
  TranslateService
} from '@tolgee/ngx';

import {
  debounceTime,
  filter,
  map
} from 'rxjs';
import { emulateClick } from '../core/a11y';
import { DrawerService } from '../drawer/drawer.service';
import { HoverGradientDirective } from '../shared/directives/hover-gradient.directive';
import { ProductGroup } from '../shared/model/product-group.model';
import { Product } from '../shared/model/product.model';
import { AnalyticsService } from '../shared/services/analytics.service';
import { ProductService } from '../shared/services/product.service';
import { SiteService } from '../shared/services/site.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  standalone: true,
  imports: [
    NgxTolgeeModule,
    NgOptimizedImage,
    RouterLink,
    CurrencyPipe,
    HoverGradientDirective,
    NgClass,
    CdkTrapFocus,
  ]
})
export class SearchComponent implements AfterViewInit {
  readonly emulateClick = emulateClick;
  siteService = inject(SiteService);
  drawerService = inject(DrawerService);
  translateService = inject(TranslateService);
  filter = signal('');
  private analyticsService = inject(AnalyticsService);
  private productService = inject(ProductService);
  products = computed(() => {
    const filter = this.filter();
    const products = this.productService.ofCurrentCenter();
    if (!filter) {
      return null;
    }
    const words = filter.split(' ').map(word => word.normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
    return products
      .filter(p => words
        .every(word => p.name
          .trim()
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(word.trim().toLowerCase()) ||
          this.translateService.instant('product/' + p.id + '/name')
            .trim()
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .includes(word.trim().toLowerCase())
        ));
  });

  constructor() {
    Keyboard.setScroll({ isDisabled: true });
    toObservable(this.filter)
      .pipe(
        takeUntilDestroyed(),
        debounceTime(500),
        map(term => term.trim()),
        filter(term => !!term)
      )
      .subscribe(term => this.analyticsService.search(term));
  }

  ngAfterViewInit(): void {
    Keyboard.setScroll({ isDisabled: false });
  }

  isGroup: (product: Product | ProductGroup) => boolean = product => product instanceof ProductGroup;

  price: (product: Product | ProductGroup) => { price: number, fullPrice: number, promo: boolean } = product => {
    const seletectedProduct = product instanceof ProductGroup ?
      product.products.reduce((cheapest, product) => {
        const cheapestPrices = this.siteService.price(cheapest.id);
        const tentativePrice = this.siteService.price(product.id);
        return cheapestPrices.price - tentativePrice.price < 0 ?
          cheapest :
          product
      },
      product.products[0]) :
      product;
    return this.siteService.price(seletectedProduct.id)
  };
}
