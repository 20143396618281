import { Injectable } from "@angular/core";
import {
  AppUpdate,
  AppUpdateAvailability,
  AppUpdateResultCode,
  FlexibleUpdateInstallStatus
} from "@capawesome/capacitor-app-update";

@Injectable({
  providedIn: 'root',
})
export class UpdateService {
  update = async (): Promise<void> => {
    const info = await AppUpdate.getAppUpdateInfo()
    console.error('update info', info)
    if (info.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE) {
      return;
    }

    if (info.flexibleUpdateAllowed) {
      const result = await AppUpdate.startFlexibleUpdate();
      if (result.code !== AppUpdateResultCode.OK) {
        await this.update();
      }
      const updated = await new Promise(resolve => {
        AppUpdate.addListener('onFlexibleUpdateStateChange', state => {
          if (state.installStatus === FlexibleUpdateInstallStatus.INSTALLED) {
            resolve(true);
          }
          if (state.installStatus === FlexibleUpdateInstallStatus.CANCELED ||
            state.installStatus === FlexibleUpdateInstallStatus.FAILED) {
            resolve(false)
          }
        })
      })
      if (!updated) {
        await this.update();
      }
      await AppUpdate.completeFlexibleUpdate();
      return;

    }
    if (info.immediateUpdateAllowed) {
      const result = await AppUpdate.performImmediateUpdate();
      if (result.code !== AppUpdateResultCode.OK) {
        await this.update();
      }
      return;
    }
    throw new Error("Cannot update: Should not happen");
  }

  openAppStore = async (): Promise<void> => {
    await AppUpdate.openAppStore();
  }
}
